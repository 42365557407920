/* The Modal (background) */
.modal-thank-you {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(255, 255, 255); /* Fallback color */
    background-color: rgba(255, 255, 255, 0.38); /* Black w/ opacity */
    backdrop-filter: blur(10px);
    animation: zoomIn 0.3s;
}

@keyframes zoomIn {
    0% { transform: scale(0) }
    60% { transform: scale(1.1) }
    100% { transform: scale(1.0) }
}

/* Modal Content/Box */
.modal-content-thank-you {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    border-radius: 32px;
    text-align: center;
    width: 50%; /* Could be more or less, depending on screen size */
    border: 0.5px solid #FF6E59;
    box-shadow: 6px 6px 1px #FF6E59;
    position: relative;
}

.thank-you-buttons-div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.modal-content-thank-you h1 {
    margin-top: 0;
    margin-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
}

.modal-content-thank-you p {
    font-size: 13pt;
    margin-bottom: 8px;
}

.extra-message {
    margin-top: 32px;
    border-style: solid;
    border-width: 1px;
    border-color: red;
    border-radius: 16px;
    padding: 8px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
}

.thank-you-button-go-back,
.thank-you-button-go-to-cart,
.thank-you-button-order-extra {
    margin-top: 16px;
	font-size: 18px;
    width: 180px;
	height: 50px;
	background-color: #000;
    border-radius: 22px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.thank-you-button-go-back:hover,
.thank-you-button-go-to-cart:hover,
.thank-you-button-order-extra:hover {
    background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .modal-content-thank-you {
        width: 90%;
    }

    .modal-content-thank-you h1 {
        font-size: 20pt;
    }

    .modal-content-thank-you p {
        font-size: 12pt;
    }

    .thank-you-buttons-div {
        flex-direction: column;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .modal-content-thank-you h1 {
        font-size: 19pt;
    }

    .modal-content-thank-you p {
        font-size: 11pt;
    }
}