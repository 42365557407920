.modal-content-payment-info {
    padding-left: 16px;
    padding-right: 16px;
}

.payment-info-h1 {
    margin: 0;
    padding: 0;
    padding-bottom: 32px;
    font-size: 22pt;
}

.payment-info-button {
    margin-top: 32px;
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .modal-content-payment-info {
        width: 90%;
    }

    .payment-info-h1 {
        font-size: 18pt;
    }

    .payment-info-p {
        font-size: 12pt;
    }
}