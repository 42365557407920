.item {
  width: 275px;
  min-height: 320px;
  padding: 0 0.8rem 0.8rem 0.8rem;
  background-color: #fff;
  border: 1px;
  border-radius: 30px;
  border-color: rgb(235, 235, 235);
  border-style: solid;
  -webkit-box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.01);
  -moz-box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.01);
  box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.01);
  text-align: center;
  flex-direction: column;
  display: flex;
  transition: 0.3s;
  margin-bottom: 100px;
}

.item-image-div {
  position: relative;
  cursor: pointer;
}

.item-image-div:hover > .item-meal-plan-expand-icon {
  right: 20px;
  padding: 14px;
  font-size: 16pt;
  border-radius: 32px; 
}

.item-image-div:hover > .item-image {
  width: 225px;
  -webkit-box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.10);
  -moz-box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.10);
  box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.10);
}

.item-meal-plan-expand-icon {
  position: absolute;
  bottom: 14px;
  right: 24px;
  background-color: white;
  padding: 10px;
  border-radius: 24px;
  color: #e7634f;
  transition: 0.3s;
}

.item img {
  margin-top: -3.5rem;
  width: 220px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  border-radius: 10%;
  transition: 0.3s;
}



.item-head_desc p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.item-head_desc {
  text-align: left;
  flex: 1;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
}

.head_desc-name {
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: capitalize;
}

.head_desc-info {
  font-size: 0.8rem;
  color: #a0a0a0;
}

.item-calories-div {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.price {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 30px;
  padding-left: 16px;
}

.item-add-button-div {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}



.option-list-div {
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 12px;
  padding-left: 12px;
}

.option-list-div select {
  font-family: 'Montserrat', sans-serif;
  font-size: 12pt;
  font-weight: 500;
  border-radius: 12px;
  padding: 4px;
  min-width: 100px;
}



.day-of-week-div {
  margin-top: 8px;
  margin-left: -0.8rem;
  margin-right: -0.8rem;
  margin-bottom: -0.8rem;
  background-color: #fcf0ed;
  color: #e7634f;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-color: #f5d1c7;
  border-width: 0.5px;
  border-style: solid;
  border-top-width: 0;
  overflow: hidden;
}

.day-of-week-div p {
  font-variant-caps: all-small-caps;
  font-size: 14pt;
  font-weight: 600;
  padding: 0.2rem;
  padding-top: 0;
}



/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
  .item {
    padding: 0;
    border-radius: 20px;
    margin-top: 0;
    margin-bottom: 32px;
    width: 125px;
    min-height: 216px;
  }

  .item-head_desc {
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .head_desc-name {
    font-size: 11pt;
    text-align: center;
  }

  .head_desc-info {
    visibility: hidden;
    height: 0;
  }

  .item img {
    width: 100px;
    margin-top: -1rem;
  }

  .item-calories-div {
    margin-left: auto;
  }

  .item-calories {
    font-size: 9pt;
  }

  .item-image-div:hover > .item-meal-plan-expand-icon {
    right: 10px;
    padding: 10px;
    font-size: 11pt;
  }
  
  .item-image-div:hover > .item-image {
    width: 110px;
  }
  
  .item-meal-plan-expand-icon {
    bottom: 8px;
    right: 14px;
    padding: 8px;
    font-size: 9pt;
  }

  .day-of-week-div {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  .day-of-week-div p {
    font-size: 12pt;
  }
}

/* iPhone 8 */
@media (max-width: 375px) {
  .item {
    width: 115px;
    min-height: 208px;
  }

  .head_desc-name {
    font-size: 10pt;
  }

  .item-calories {
    font-size: 8pt;
  }

  .item-image-div:hover > .item-meal-plan-expand-icon {
    right: 6px;
  }
  
  .item-image-div:hover > .item-image {
    width: 105px;
  }

  .item-meal-plan-expand-icon {
    right: 10px;
  }

  .day-of-week-div p {
    font-size: 11pt;
  }
}

/* iPhone SE */
@media (max-width: 320px) {
  .item {
    border-radius: 14px;
    width: 100px;
    min-height: 220px;
  }

  .item-image-div:hover > .item-meal-plan-expand-icon {
    right: -6px;
  }
  
  .item-image-div:hover > .item-image {
    width: 105px;
  }

  .item-meal-plan-expand-icon {
    right: 2px;
  }

  .day-of-week-div {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}