@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  margin: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
button {
  font-family: 'Montserrat', sans-serif;
}
body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f8f8f8;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  width: 95%;
}
.btnCartCount {
  width: 50px;
  height: 50px;
  background: #000;
  color: #fff;
  border-radius: 15px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  top: 1.5rem;
  cursor: pointer;
  right: 1rem;
  transition: 0.3s;
  box-shadow: 5px 5px 1px #FF6E59;
}

.btnCartCount:hover {
  background: #FF6E59;
  box-shadow: 5px 5px 1px #000;
}

.btnCartCount .count {
  position: absolute;
  background: #FF6E59;
  bottom: -8px;
  left: -9px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 0.6rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 800;
  font-size: 9pt;
}
.btnCartCount i {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

/* The Modal (background) */
.modal-post-code {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(255, 255, 255); /* Fallback color */
    background-color: rgba(255, 255, 255, 0.38); /* Black w/ opacity */
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    -webkit-animation: zoomIn 0.3s;
            animation: zoomIn 0.3s;
}

@-webkit-keyframes zoomIn {
    0% { -webkit-transform: scale(0); transform: scale(0) }
    60% { -webkit-transform: scale(1.1); transform: scale(1.1) }
    100% { -webkit-transform: scale(1.0); transform: scale(1.0) }
}

@keyframes zoomIn {
    0% { -webkit-transform: scale(0); transform: scale(0) }
    60% { -webkit-transform: scale(1.1); transform: scale(1.1) }
    100% { -webkit-transform: scale(1.0); transform: scale(1.0) }
}

/* Modal Content/Box */
.modal-content-post-code {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    border-radius: 32px;
    text-align: center;
    width: 50%; /* Could be more or less, depending on screen size */
    border: 0.5px solid #FF6E59;
    box-shadow: 6px 6px 1px #FF6E59;
    position: relative;
}

.post-code-question {
    font-size: 14pt;
    padding-left: 40px;
    padding-right: 40px;
}

.post-code-form {
    margin-top: 16px;
}

.post-code-text-field {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-feature-settings: "pcap", "c2pc";
            font-feature-settings: "pcap", "c2pc";
    font-variant-caps: all-petite-caps;
    font-size: 14pt;
    font-weight: 600;
    width: 200px;
    height: 35px;
    text-align: center;
    border-radius: 16px;
    border-style: dashed;
    border-width: 1px;
    box-shadow: 2px 2px 1px #FF6E59;
}

.post-code-text-field:focus {
    border-color: black;
    border-width: 1px;
    border-style: solid;
    box-shadow: 2px 2px 1px #FF6E59;
    outline: none;
}

.post-code-sorry {
    margin-top: 16px;
    font-size: 14pt;
    font-weight: 500;
}

.post-code-success {
    margin-top: 16px;
    font-size: 14pt;
    font-weight: 500;
}

.pop-up-response-div {
    -webkit-animation: expand 0.3s;
            animation: expand 0.3s;
}

@-webkit-keyframes expand {
    0% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
    100% { -webkit-transform: none; transform: none; }
}

@keyframes expand {
    0% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
    100% { -webkit-transform: none; transform: none; }
}

.post-code-sorry-button {
    margin-top: 16px;
	font-size: 18px;
    width: 250px;
	height: 50px;
    margin-right: 0;
    margin-left: auto;
	background-color: #000;
    border-radius: 22px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.post-code-success-button {
    margin-top: 16px;
	font-size: 18px;
    width: 270px;
	height: 50px;
    margin-right: 0;
    margin-left: auto;
	background-color: #000;
    border-radius: 26px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.post-code-success-button:hover {
	background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

.post-code-icon {
    padding-right: 6px;
}

.modal-close-button-post-code {
    position: absolute;
    right: 16px;
    top: 16px;
	font-size: 18px;
    width: 50px;
	height: 50px;
    margin-right: 0;
    margin-left: auto;
	background-color: #000;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-radius: 15px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.modal-close-button-post-code:hover {
    background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

.modal-close-button-post-code i {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}


/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .modal-content-post-code {
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
        width: 90%;
    }

    .post-code-question {
        font-size: 12pt;
        text-align: left;
        padding-left: 20px;
        padding-right: 80px;
    }

    .post-code-sorry, .post-code-success {
        font-size: 11pt;
        padding-left: 6px;
        padding-right: 6px;
        font-weight: 500;
    }

    .post-code-sorry-button {
        font-size: 16px;
        width: 220px;
    }

    .post-code-success-button {
        font-size: 16px;
        width: 270px;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .modal-content-post-code {
        width: 91%;
    }

    .post-code-question {
        font-size: 11pt;
        text-align: left;
        padding-left: 20px;
        padding-right: 80px;
    }

    .post-code-sorry {
        font-size: 10pt;
    }

    .post-code-sorry-button {
        font-size: 15px;
        width: 200px;
    }

    .post-code-success-button {
        font-size: 15px;
        width: 260px;
    }
}
.item-meal-plan {
    width: 375px;
    padding: 0 0.8rem 0.8rem 0.8rem;
    background-color: #fff;
    border-radius: 30px;
    margin-right: 16px;
    text-align: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    box-shadow: 6px 6px 1px #FF6E59;
    border-color: #FF6E59;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 16px;
}

.item-meal-plan img {
    margin-top: -3.5rem;
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    border-radius: 10%;
}

.meal-plan-name {
    font-size: 20pt;
    font-weight: 600;
}

.meal-plan-info {
    font-size: 12pt;
}

.meal-plan-button {
    background: #000;
    color: #fff;
    width: 140px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem 1.5rem;
    outline: none;
    border: none;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 1rem;
    transition: 0.3s;
    box-shadow: 6px 6px 1px #FF6E59;
    margin-left: auto;
    margin-right: 0;
    font-weight: 400;
}

.meal-plan-button:hover {
    background: #FF6E59;
    box-shadow: 6px 6px 1px #000;
}

.item-meal-plan-price {
    font-size: 16pt;
    font-weight: 600;
    padding-left: 16px;
}

  /* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .meal-plans-main {
      grid-template-columns: 1fr;
    }

    .item-meal-plan {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .item-meal-plan img {
        width: 260px;
    }

    .meal-plan-name {
        font-size: 19pt;
    }

    .item-meal-plan-price {
        padding-left: 6px;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .item-meal-plan {
        width: 280px;
    }

    .item-meal-plan img {
        width: 240px;
    }

    .meal-plan-name {
        font-size: 18pt;
    }

    .item-meal-plan-price {
        padding-left: 4px;
    }
}

/* iPhone SE */
@media (max-width: 320px) {
    .item-meal-plan {
        width: 260px;
    }

    .item-meal-plan img {
        width: 220px;
    }

    .meal-plan-name {
        font-size: 16pt;
    }

    .item-meal-plan-price {
        padding-left: 2px;
    }
}  
.meal-plans-div {
  margin-top: 20px;
  margin-bottom: 48px;
  padding-top: 3.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -webkit-align-items: stretch;
          align-items: stretch;
  grid-row-gap: 5rem;
  scroll-margin: 160px;
}

.meal-plans-desc {
  text-align: center;
  font-size: 18pt;
  margin-bottom: 32px; 
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
  .meal-plans-div {
    grid-template-columns: 1fr;
  }

  .meal-plans-desc {
    font-size: 16pt;
  }
}
.header-content_logo {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.header-content_logo img {
  width: 1.5rem;
  margin-right: 0.4rem;
}
.header-content_logo > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.header-content_logo > p {
  font-size: 0.5rem;
}
.content-logo img {
  width: 1.5rem;
}

.mokka-footer {
  border-top: 1px solid #000;
  margin-top: 3rem;
  padding: 2rem 0 1rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

footer div p {
  font-weight: 500;
  max-width: 600px;
  margin-right: 16px;
}



.credits-footer {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 32px;
  text-align: right;
  float: right;
}

.credits-footer a, .button-link {
  color: black;
  font-weight: 500;
  -webkit-font-feature-settings: "pcap", "c2pc";
          font-feature-settings: "pcap", "c2pc";
  font-variant-caps: all-petite-caps;
  font-size: 14pt;
  text-decoration: underline;
}

.button-link {
  padding: 0;
  margin: 0;
  border-style: none;
  background: none;
  text-align: right;
}

.credits-footer a:hover, .button-link {
  cursor: pointer;
}



.recyclable-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.recyclable-div p {
  font-size: 16pt;
  font-weight: 500;
}

.recyclable-div img {
  width: 35px;
  height: auto;
  margin-left: 4px;
}



.item-heart {
  font-size: 1.4rem;
}

.item-thank-you {
  font-size: 1.2rem;
}


.footer-version {
  position: absolute;
  font-size: 10pt;
  color: lightgray;
}


/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
  .mokka-footer p {
    font-size: 0.9rem;
  }

  .item-thank-you {
    font-size: 1.1rem;
  }

  .item-heart {
    font-size: 1.1rem;
  }

  .recyclable-div {
    margin-top: 24px;
  }

  .recyclable-div p {
    font-size: 12pt;
  }

  .recyclable-div img {
    width: 25px;
    margin-left: 0;
  }
}

/* iPhone 8 */
@media (max-width: 375px) {
  .recyclable-div p {
    font-size: 11pt;
  }  
}

/* iPhone SE */
@media (max-width: 320px) {
  .recyclable-div p {
    font-size: 10pt;
  }  
}
header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.5rem 0 0 0;
  height: 100vh;
}

.company-name {
  -webkit-animation: comeFromLeft 0.3s;
          animation: comeFromLeft 0.3s;
}

.company-info {
  -webkit-animation: comeFromLeft 0.6s;
          animation: comeFromLeft 0.6s;
}

.view-plans-button {
  -webkit-animation: comeFromLeft 0.9s;
          animation: comeFromLeft 0.9s;
}

.header-img, .header-mobile-img {
  width: 100%;
  -webkit-align-self: center;
          align-self: center;
  border-radius: 15%;
  -webkit-animation: comeFromRight 0.3s;
          animation: comeFromRight 0.3s;
}

.header-mobile-img {
  visibility: hidden;
  height: 0px;
}

@-webkit-keyframes comeFromLeft {
  0% { -webkit-transform: translateX(-500px); transform: translateX(-500px); }
  100% { -webkit-transform: none; transform: none; }
}

@keyframes comeFromLeft {
  0% { -webkit-transform: translateX(-500px); transform: translateX(-500px); }
  100% { -webkit-transform: none; transform: none; }
}

@-webkit-keyframes comeFromRight {
  0% { -webkit-transform: translateX(500px); transform: translateX(500px); }
  100% { -webkit-transform: none; transform: none; }
}

@keyframes comeFromRight {
  0% { -webkit-transform: translateX(500px); transform: translateX(500px); }
  100% { -webkit-transform: none; transform: none; }
}

.header-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.content-main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: center;
          justify-content: center;
}

.content-main h1 {
  margin-left: 0;
  font-size: 4rem;
  margin-bottom: 1.5rem;
}

.content-main button {
  background: #000;
  color: #fff;
  width: 220px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 2.5rem;
  transition: 0.3s;
  box-shadow: 6px 6px 1px #FF6E59;
}

.content-main button:hover {
  background: #FF6E59;
  box-shadow: 6px 6px 1px #000;
}

.content-main p {
  font-weight: 500;
  font-size: 16pt;
}

.soon-available {
  font-size: 12pt;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
  header {
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
    text-align: center;
  }

  header .header-img {
    visibility: hidden;
    height: 0px;
  }

  header .header-mobile-img {
    visibility: visible;
    width: 90%;
    height: auto;
    margin-top: 32px;
  }

  .content-main button {
    margin-left: auto;
    margin-right: auto;
  }
  
  .content-main h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .content-main p {
    font-size: 14pt;
    padding-left: 16px;
    padding-right: 16px;
  }

  .soon-available {
    font-size: 11pt;
  }
}

/* iPhone SE */
@media (max-width: 320px) {
  .content-main h1 {
    font-size: 2.5rem;
  }

  .content-main p {
    font-size: 12pt;
  }

  .soon-available {
    font-size: 10pt;
  }
}
.what-we-serve-div-about-us {
    text-align: center;
}

.what-we-serve-div-about-us h1 {
    margin-top: 16px;
    margin-bottom: 32px;
    font-size: 32pt;
}

.what-we-serve-images-div-about-us {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.what-we-serve-images-div-about-us div {
    width: 50%;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
    margin-top: 16px;
}

.what-we-serve-images-div-about-us div img {
    max-width: 100%;
    height: 200px;
    margin-bottom: 16px;
}

.what-we-serve-images-div-about-us div p {
    font-weight: 600;
    font-size: 18pt;
}

.what-we-serve-images-div-about-us .small {
    font-size: 12pt;
}



.main-div-about-us img {
    max-width: 100%;
    max-height: 400px;
}

.main-div-about-us .left-item p {
    padding-left: 84px;
}

.main-div-about-us .right-item p {
    padding-right: 84px;
}

.main-div-about-us .right-item {
    margin-left: 16px;
}

.main-div-about-us .left-item {
    margin-right: 16px;
}

.main-div-about-us p {
    font-size: 22pt;
    line-height: 24pt;
}

.main-div-about-us .small-left, .main-div-about-us .small-right {
    font-size: 14pt;
    line-height: 16pt;
    margin-bottom: 0;
}

.main-div-about-us .small-left {
    text-align: left;
}

.main-div-about-us .small-right {
    text-align: right;
}

.lets-get-started h1 {
    font-size: 32pt;
    margin-top: 32px;
    margin-bottom: 32px;
    text-align: center;
}



.delivery-info-div {
    margin-top: 64px;
    margin-bottom: 128px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-width: 10x;
    border-color: #FF6E59;
    border-style: solid;
    border-radius: 32px;
    width: 690px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    background-color: white;
    box-shadow: 6px 6px 1px #FF6E59;
    position: relative;
}

.delivery-info {
    padding-left: 26px;
    font-size: 13pt;
    font-weight: 400;
    text-align: center;
}

.info-icon {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 10px;
    margin-left: 16px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #FF6E59;
}

.info-icon .fa {
    color: white;
    margin-top: 7px;
    margin-left: 12px;
}



.about-us-item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 100px;
}



.image-with-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 200px;
}

.text-under-image {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.text-under-image p {
    font-size: 14pt;
    color: #ef6a56;
    -webkit-font-feature-settings: "pcap", "c2pc";
            font-feature-settings: "pcap", "c2pc";
    font-variant-caps: all-petite-caps;
    font-weight: 700;
}

.text-image-left {
    text-align: left;
}

.text-image-right {
    text-align: right;
}   



.mobile-div {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 0px;
}

.mobile-small {
    padding-left: 6px;
    padding-right: 6px;
    text-align: center;
    visibility: hidden;
    height: 0;
}

.delivery-weekend-soon {
    font-size: 11pt;
}


/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .what-we-serve-div-about-us {
        margin-top: 64px;
    }

    .what-we-serve-images-div-about-us div {
        padding-left: 2%;
        padding-right: 2%;
    }

    .what-we-serve-images-div-about-us div p {
        font-size: 14pt;
        margin-bottom: 4px;
    }

    .what-we-serve-images-div-about-us div img {
        height: 100px;
    }

    .what-we-serve-images-div-about-us .small {
        font-size: 11pt;
    }

    .what-we-serve-div-about-us h1 {
        font-size: 24pt;
    }

    .main-div-about-us .left-item p {
        padding-left: 0px;
    }
    
    .main-div-about-us .right-item p {
        padding-right: 0px;
    }

    .main-div-about-us .right-item {
        margin-left: 3px;
    }
    
    .main-div-about-us .left-item {
        margin-right: 3px;
    }

    .main-div-about-us .small-left, .main-div-about-us .small-right {
        visibility: hidden;
        height: 0;
    }

    .mobile-small {
        visibility: visible;
        height: auto;
    }

    .main-div-about-us img {
        height: 200px;
    }

    .main-div-about-us p {
        font-size: 14pt;
        line-height: 18pt;
    }

    .lets-get-started h1 {
        font-size: 24pt;
    }

    .text-under-image p {
        font-size: 12pt;
        line-height: 10pt;
    }

    .mobile-div {
        margin-bottom: 64px;
    }

    .about-us-item {
        margin-bottom: 64px;
    }

    .delivery-info-div {
        margin-bottom: 82px;
        width: 380px;
    }
    
    .delivery-info {
        padding-left: 32px;
        padding-right: 22px;
        font-size: 12pt;
    }
}

@media (max-width: 410px) {
    .delivery-info-div {
        width: 365px;
    }

    .delivery-info {
        padding-left: 30px;
        padding-right: 20px;
        font-size: 11pt;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .what-we-serve-images-div-about-us div p {
        font-size: 13pt;
    }

    .what-we-serve-images-div-about-us .small {
        font-size: 10pt;
    }

    .what-we-serve-images-div-about-us div img {
        height: 100px;
    }

    .what-we-serve-div-about-us h1 {
        font-size: 22pt;
    }

    .main-div-about-us img {
        height: 190px;
    }

    .main-div-about-us .right-item {
        margin-left: 0;
    }
    
    .main-div-about-us .left-item {
        margin-right: 0;
    }

    .main-div-about-us p {
        font-size: 13pt;
        line-height: 17pt;
    }

    .delivery-info-div {
        width: 340px;
        box-shadow: 4px 4px 1px #FF6E59;
    }
    
    .delivery-info {
        font-size: 10pt;
    }
}

/* iPhone SE */
@media (max-width: 320px) {
    .what-we-serve-images-div-about-us div p {
        font-size: 12pt;
    }

    .what-we-serve-images-div-about-us .small {
        font-size: 9pt;
    }

    .main-div-about-us img {
        height: 180px;
    }

    .main-div-about-us p {
        font-size: 12pt;
        line-height: 16pt;
    }

    .mobile-small {
        font-size: 10pt;
    }

    .delivery-info-div {
        width: 290px;
    }
    
    .delivery-info {
        font-size: 8pt;
    }
}
.check-delivery-button {
    position: fixed;
    top: 1.5rem;
    left: 1rem;
    background: #000;
    color: #fff;
    width: 280px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem 1.5rem;
    outline: none;
    border: none;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 6px 6px 1px #FF6E59;
}

.check-delivery-button:hover {
    background: #FF6E59;
    box-shadow: 6px 6px 1px #000;
}

.header-background {
    position: fixed;
    top: 0rem;
    left: 0rem;
    width: 100vw;
    height: 105px;
    background-color: #f8f8f87d;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .header-background {
        background-color: transparent;
        visibility: hidden;
        opacity: 0;
    }

    .check-delivery-button {
        width: 202px;
        padding: 0.85rem 0.88rem;
        font-size: 0.75rem;
        box-shadow: 4px 4px 1px #FF6E59;
    }
}
/* The Modal (background) */
.modal-are-you-sure {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(255, 255, 255); /* Fallback color */
    background-color: rgba(255, 255, 255, 0.38); /* Black w/ opacity */
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    -webkit-animation: zoomIn 0.3s;
            animation: zoomIn 0.3s;
}

@-webkit-keyframes zoomIn {
    0% { -webkit-transform: scale(0); transform: scale(0) }
    60% { -webkit-transform: scale(1.1); transform: scale(1.1) }
    100% { -webkit-transform: scale(1.0); transform: scale(1.0) }
}

@keyframes zoomIn {
    0% { -webkit-transform: scale(0); transform: scale(0) }
    60% { -webkit-transform: scale(1.1); transform: scale(1.1) }
    100% { -webkit-transform: scale(1.0); transform: scale(1.0) }
}

/* Modal Content/Box */
.modal-content-are-you-sure {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    border-radius: 32px;
    text-align: center;
    width: 600px; /* Could be more or less, depending on screen size */
    border: 0.5px solid #FF6E59;
    box-shadow: 6px 6px 1px #FF6E59;
    position: relative;
}

.are-you-sure-h1 {
    padding: 0;
    margin: 0;
    padding-bottom: 6px;
}

.are-you-sure-buttons-div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.are-you-sure-yes-button,
.are-you-sure-no-button {
    margin-top: 16px;
	font-size: 18px;
    width: 180px;
	height: 50px;
	background-color: #000;
    border-radius: 26px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.button-icon {
    padding-right: 4px;
}

.are-you-sure-yes-button {
    box-shadow: 4px 4px 1px #f14e34;
}

.are-you-sure-yes-button:hover,
.are-you-sure-no-button:hover {
	background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

.are-you-sure-yes-button:hover {
    background-color: #f14e34;
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .modal-content-are-you-sure {
        width: 80%;
    }

    .are-you-sure-buttons-div {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }

    .are-you-sure-h1 {
        font-size: 20pt;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .are-you-sure-h1 {
        font-size: 18pt;
    }
}
.menu-cart-main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.meal-plan-cart-item-div {
    max-width: 520px;
    padding: 20px;
    margin-left: 32px;
    margin-top: 16px;
    border-style: solid;
    border-color: #FF6E59;
    border-width: 1px;
    border-radius: 16px;
    display: -webkit-flex;
    display: flex;
    box-shadow: 6px 6px 1px #FF6E59;
}

.meal-plan-cart-item-div img {
    width: 200px;
    border-radius: 16px;
    margin-left: -48px;
    margin-top: -18px;
    margin-bottom: -16px;
}

.meal-plan-cart-item-info-div h1 {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.meal-plan-cart-item-info-div p {
    margin-left: 16px;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .meal-plan-cart-item-div img {
        width: 150px;
    }

    .meal-plan-cart-item-info-div h1 {
        font-size: 16pt;
        margin-left: 12px;
    }
    
    .meal-plan-cart-item-info-div p {
        font-size: 11pt;
        margin-left: 12px;
    }
}

/* iPhone 8 */
@media (max-width: 400px) {
    .meal-plan-cart-item-div img {
        width: 140px;
    }

    .meal-plan-cart-item-info-div h1 {
        font-size: 14pt;
        margin-left: 10px;
    }
    
    .meal-plan-cart-item-info-div p {
        font-size: 10pt;
        margin-left: 10px;
    }
}

/* iPhone SE */
@media (max-width: 330px) {
    .meal-plan-cart-item-div img {
        width: 120px;
    }

    .meal-plan-cart-item-info-div h1 {
        font-size: 12pt;
        margin-left: 8px;
    }
    
    .meal-plan-cart-item-info-div p {
        font-size: 9pt;
        margin-left: 8px;
    }
}
.emptyCart {
  height: calc(100vh - 13.7rem);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.emptyCart p {
  font-size: 22pt;
}
.do-not-close {
    font-size: 14pt;
    font-weight: 500;
}

.loading-icon {
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 42pt;
    color: #ff6d57;
    box-shadow: 0px 4px 6px #ff8d7c;
    border-radius: 50%;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .modal-content-loading {
        width: 90%;
        padding-left: 12px;
        padding-right: 12px;
    }

    .loading-h1 {
        font-size: 18pt;
    }

    .loading-p {
        font-size: 12pt;
    }
}

/* iPhone 8 */
@media (max-width: 400px) {
    .loading-h1 {
        font-size: 17pt;
    }
}

/* iPhone SE */
@media (max-width: 330px) {
    .loading-h1 {
        font-size: 15pt;
    }

    .loading-p {
        font-size: 11pt;
    }
}
.modal-content-payment-info {
    padding-left: 16px;
    padding-right: 16px;
}

.payment-info-h1 {
    margin: 0;
    padding: 0;
    padding-bottom: 32px;
    font-size: 22pt;
}

.payment-info-button {
    margin-top: 32px;
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .modal-content-payment-info {
        width: 90%;
    }

    .payment-info-h1 {
        font-size: 18pt;
    }

    .payment-info-p {
        font-size: 12pt;
    }
}
.FormGroup {
	border-radius: 12px;
	padding: 6px;
	border-style: dashed;
	border-width: 1px;
	border-color: black;
	background-color: white;
	box-shadow: 0px 0px 5px #ffd1ca;
}

.FormRow {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-left: 15px;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

.payment-button {
	background: #000;
	color: #fff;
	width: 353px;
	padding: 1rem 1.5rem;
	display: -webkit-flex;
	display: flex;
	outline: none;
	border: none;
	font-size: 1rem;
	border-radius: 25px;
	cursor: pointer;
	margin-top: 1.5rem;
	transition: 0.3s;
	box-shadow: 6px 6px 1px #FF6E59;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin-left: auto;
	margin-right: 0;
	font-weight: 500;
}

.payment-button:hover {
	background-color: #FF6E59;
	box-shadow: 6px 6px 1px #000;
}

h3 {
	margin-left: 15px;
	margin-top: 50px;
}

.stripe-link {
	color: black;
	text-decoration: none;
}

.stripe-div {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	font-size: 11pt;
	font-weight: 500;
	margin-top: 8px;
}

.stripe-logo {
	width: 65px;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .payment-button {
		margin-right: auto;
	}
}
h1 {
    margin-left: 10px;
    margin-top: 100px;
    margin-bottom: 50px;
}

.user-payment-form {
    margin-top: 32px;
    margin-bottom: 20px;
}

.user-payment-form input[type=text], .user-payment-form select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 11pt;
    font-family: 'Montserrat', sans-serif;
    border-radius: 12px;
    border-style: dashed;
	border-width: 1px;
	border-color: black;
	box-shadow: 0px 0px 5px #ffd1ca;
}

.user-payment-form-customer-details,
.user-payment-form-email,
.user-payment-form-address {
    display: -webkit-flex;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.user-payment-form-address-street-name {
    -webkit-flex: 3.8 1;
            flex: 3.8 1;
}

.user-payment-form-address-street-number {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.user-payment-form-address-post-code {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.user-payment-form-address-city {
    -webkit-flex: 1 1;
            flex: 1 1;
    background-color: rgb(246, 241, 232);
}

.user-payment-form-description {
    font-size: 12pt;
    padding-left: 3px;
    margin-top: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.user-payment-form-card-description {
    margin-bottom: 12px;
}

.user-payment-form-delivery-note {
    width: 100%;
    height: 100px;
    resize: none;
    font-size: 12pt;
    margin-top: 1rem;
    padding: 12px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    border-radius: 12px;
    border-style: dashed;
	border-width: 1px;
	border-color: black;
	box-shadow: 0px 0px 5px #ffd1ca;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .user-payment-form-customer-details,
    .user-payment-form-email,
    .user-payment-form-address {
        -webkit-flex-direction: column;
                flex-direction: column;
        grid-gap: 1px;
        gap: 1px;
    }

    .user-payment-form-description {
        font-size: 11pt;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .user-payment-form-description {
        font-size: 10pt;
    }
}
.cart-header {
  padding-top: 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.orders {
  margin: 2rem 0 0 0;
  min-height: calc(100vh - 15rem);
}

.orders-heading,
.orders-total-description-item,
.orders-total-description-plan,
.orders-total-description,
.orders-total {
  width: 100%;
  max-width: 600px;
}

.orders-heading {
  margin-bottom: 0;
  border-bottom: 2px solid black;
}

.orders-total {
  text-align: right;
  border-top: 2px solid #000;
  margin-left: auto;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 24pt;
}

.orders-total-description {
  text-align: right;
  border-top: 2px solid #000;
  margin-left: auto;
  margin-top: 3rem;
}

.order-description-your-total-label {
  font-size: 16pt;
}

.orders-total-description-item,
.orders-total-description-plan {
  text-align: right;
  margin-left: auto;
  margin-top: 0.1rem; 
}

.orders-total-description-plan {
  font-size: 14pt;
  font-weight: 600;
}

.orders-total-description-item {
  font-size: 11pt;
  font-weight: 400;
}

.order-description-item-price {
  padding-left: 10px;
}

.order-description-delivery-price {
  padding-left: 20px;
}

.orders-delivery-date {
  padding-left: 10px;
  padding-top: 16px;
  font-size: 16pt;
  font-weight: 400;
}

.orders-delivery-date-desc {
  padding-left: 10px;
}

.dev-email {
  color: white;
  font-weight: 700;
  text-decoration: underline;
}
 

.back-button {
  background: #000;
  color: #fff;
  width: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 2.5rem;
  transition: 0.3s;
  box-shadow: 6px 6px 1px #FF6E59;
}

.back-button:hover {
  background: #FF6E59;
  box-shadow: 6px 6px 1px #000;
}



.clear-cart-button {
  background: #000;
  color: #fff;
  width: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 2.5rem;
  transition: 0.3s;
  box-shadow: 6px 6px 1px #f14e34;
}

.clear-cart-button:hover {
  background: #f14e34;
  box-shadow: 6px 6px 1px #000;
}
.modal-double-item-content {
    padding-left: 60px;
    padding-right: 60px;
    width: 700px;
}

.double-item-buttons-div {
    margin-top: 12px;
    margin-bottom: 6px;
}

.double-item-yes-button {
    width: 235px;
}

.double-item-no-button {
    width: 265px;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .modal-double-item-content {
        padding-left: 40px;
        padding-right: 40px;
        width: 90%;
    }

    .double-item-p {
        margin-top: 16px;
        font-size: 11pt;
    }

    .double-item-yes-button {
        font-size: 13pt;
        width: 250px;
    }
    
    .double-item-no-button {
        font-size: 13pt;
        width: 250px;
    }
}

/* iPhone SE */
@media (max-width: 320px) {
    .modal-double-item-content {
        padding-left: 30px;
        padding-right: 30px;
        width: 95%;
    }

    .double-item-h1 {
        margin-right: 50px;
    }
}
/* The Modal (background) */
.modal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(255, 255, 255); /* Fallback color */
    background-color: rgba(255, 255, 255, 0.18); /* Black w/ opacity */
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    -webkit-animation: zoomIn 0.3s;
            animation: zoomIn 0.3s;
}

@-webkit-keyframes zoomIn {
    0% { -webkit-transform: scale(0); transform: scale(0) }
    60% { -webkit-transform: scale(1.1); transform: scale(1.1) }
    100% { -webkit-transform: scale(1.0); transform: scale(1.0) }
}

@keyframes zoomIn {
    0% { -webkit-transform: scale(0); transform: scale(0) }
    60% { -webkit-transform: scale(1.1); transform: scale(1.1) }
    100% { -webkit-transform: scale(1.0); transform: scale(1.0) }
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 6% auto; /* 15% from the top and centered */
    padding: 20px;
    width: 60%; /* Could be more or less, depending on screen size */
    border-radius: 32px;
    /* border: 0.5px solid #FF6E59; */
    /* box-shadow: 6px 6px 1px #FF6E59; */
    box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.3);
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.modal-close-button {
    position: absolute;
    right: -130px;
    top: 16px;
	font-size: 18px;
    width: 110px;
	height: 50px;
    margin-right: 0;
    margin-left: auto;
	background-color: #000;
    border-radius: 20px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.modal-close-button:hover {
    background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

.menu-pop-up-img {
    width: 95%;
    margin-top: -8%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
}

.meal-name {
    padding: 0;
    margin-top: 16px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}

.meal-name, .meal-info, .pop-up-item-calories {
    padding: 6px;
    padding-left: 18px;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .modal-content {
        margin: 30% auto;
        width: 95%;
    }

    .menu-pop-up-img {
        margin-top: -15%;
        width: 100%;
    }

    .meal-name, .meal-info, .pop-up-item-calories {
        padding: 6px;
    }

    .modal-close-button {
        position: fixed;
        right: 10px;
    }
}
/* The Modal (background) */
.modal-thank-you {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(255, 255, 255); /* Fallback color */
    background-color: rgba(255, 255, 255, 0.38); /* Black w/ opacity */
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    -webkit-animation: zoomIn 0.3s;
            animation: zoomIn 0.3s;
}

@-webkit-keyframes zoomIn {
    0% { -webkit-transform: scale(0); transform: scale(0) }
    60% { -webkit-transform: scale(1.1); transform: scale(1.1) }
    100% { -webkit-transform: scale(1.0); transform: scale(1.0) }
}

@keyframes zoomIn {
    0% { -webkit-transform: scale(0); transform: scale(0) }
    60% { -webkit-transform: scale(1.1); transform: scale(1.1) }
    100% { -webkit-transform: scale(1.0); transform: scale(1.0) }
}

/* Modal Content/Box */
.modal-content-thank-you {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    border-radius: 32px;
    text-align: center;
    width: 50%; /* Could be more or less, depending on screen size */
    border: 0.5px solid #FF6E59;
    box-shadow: 6px 6px 1px #FF6E59;
    position: relative;
}

.thank-you-buttons-div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
}

.modal-content-thank-you h1 {
    margin-top: 0;
    margin-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
}

.modal-content-thank-you p {
    font-size: 13pt;
    margin-bottom: 8px;
}

.extra-message {
    margin-top: 32px;
    border-style: solid;
    border-width: 1px;
    border-color: red;
    border-radius: 16px;
    padding: 8px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
}

.thank-you-button-go-back,
.thank-you-button-go-to-cart,
.thank-you-button-order-extra {
    margin-top: 16px;
	font-size: 18px;
    width: 180px;
	height: 50px;
	background-color: #000;
    border-radius: 22px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.thank-you-button-go-back:hover,
.thank-you-button-go-to-cart:hover,
.thank-you-button-order-extra:hover {
    background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .modal-content-thank-you {
        width: 90%;
    }

    .modal-content-thank-you h1 {
        font-size: 20pt;
    }

    .modal-content-thank-you p {
        font-size: 12pt;
    }

    .thank-you-buttons-div {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .modal-content-thank-you h1 {
        font-size: 19pt;
    }

    .modal-content-thank-you p {
        font-size: 11pt;
    }
}
.item {
  width: 275px;
  min-height: 320px;
  padding: 0 0.8rem 0.8rem 0.8rem;
  background-color: #fff;
  border: 1px;
  border-radius: 30px;
  border-color: rgb(235, 235, 235);
  border-style: solid;
  box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.01);
  text-align: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: flex;
  transition: 0.3s;
  margin-bottom: 100px;
}

.item-image-div {
  position: relative;
  cursor: pointer;
}

.item-image-div:hover > .item-meal-plan-expand-icon {
  right: 20px;
  padding: 14px;
  font-size: 16pt;
  border-radius: 32px; 
}

.item-image-div:hover > .item-image {
  width: 225px;
  box-shadow: 6px 6px 16px 6px rgba(0,0,0,0.10);
}

.item-meal-plan-expand-icon {
  position: absolute;
  bottom: 14px;
  right: 24px;
  background-color: white;
  padding: 10px;
  border-radius: 24px;
  color: #e7634f;
  transition: 0.3s;
}

.item img {
  margin-top: -3.5rem;
  width: 220px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  border-radius: 10%;
  transition: 0.3s;
}



.item-head_desc p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.item-head_desc {
  text-align: left;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
}

.head_desc-name {
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: capitalize;
}

.head_desc-info {
  font-size: 0.8rem;
  color: #a0a0a0;
}

.item-calories-div {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.price {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 30px;
  padding-left: 16px;
}

.item-add-button-div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}



.option-list-div {
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 12px;
  padding-left: 12px;
}

.option-list-div select {
  font-family: 'Montserrat', sans-serif;
  font-size: 12pt;
  font-weight: 500;
  border-radius: 12px;
  padding: 4px;
  min-width: 100px;
}



.day-of-week-div {
  margin-top: 8px;
  margin-left: -0.8rem;
  margin-right: -0.8rem;
  margin-bottom: -0.8rem;
  background-color: #fcf0ed;
  color: #e7634f;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-color: #f5d1c7;
  border-width: 0.5px;
  border-style: solid;
  border-top-width: 0;
  overflow: hidden;
}

.day-of-week-div p {
  -webkit-font-feature-settings: "smcp", "c2sc";
          font-feature-settings: "smcp", "c2sc";
  font-variant-caps: all-small-caps;
  font-size: 14pt;
  font-weight: 600;
  padding: 0.2rem;
  padding-top: 0;
}



/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
  .item {
    padding: 0;
    border-radius: 20px;
    margin-top: 0;
    margin-bottom: 32px;
    width: 125px;
    min-height: 216px;
  }

  .item-head_desc {
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .head_desc-name {
    font-size: 11pt;
    text-align: center;
  }

  .head_desc-info {
    visibility: hidden;
    height: 0;
  }

  .item img {
    width: 100px;
    margin-top: -1rem;
  }

  .item-calories-div {
    margin-left: auto;
  }

  .item-calories {
    font-size: 9pt;
  }

  .item-image-div:hover > .item-meal-plan-expand-icon {
    right: 10px;
    padding: 10px;
    font-size: 11pt;
  }
  
  .item-image-div:hover > .item-image {
    width: 110px;
  }
  
  .item-meal-plan-expand-icon {
    bottom: 8px;
    right: 14px;
    padding: 8px;
    font-size: 9pt;
  }

  .day-of-week-div {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  .day-of-week-div p {
    font-size: 12pt;
  }
}

/* iPhone 8 */
@media (max-width: 375px) {
  .item {
    width: 115px;
    min-height: 208px;
  }

  .head_desc-name {
    font-size: 10pt;
  }

  .item-calories {
    font-size: 8pt;
  }

  .item-image-div:hover > .item-meal-plan-expand-icon {
    right: 6px;
  }
  
  .item-image-div:hover > .item-image {
    width: 105px;
  }

  .item-meal-plan-expand-icon {
    right: 10px;
  }

  .day-of-week-div p {
    font-size: 11pt;
  }
}

/* iPhone SE */
@media (max-width: 320px) {
  .item {
    border-radius: 14px;
    width: 100px;
    min-height: 220px;
  }

  .item-image-div:hover > .item-meal-plan-expand-icon {
    right: -6px;
  }
  
  .item-image-div:hover > .item-image {
    width: 105px;
  }

  .item-meal-plan-expand-icon {
    right: 2px;
  }

  .day-of-week-div {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}
.menu-main {
  padding-top: 3.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-row-gap: 5rem;
  -webkit-animation: rollout 0.4s;
          animation: rollout 0.4s;
}

.menu-meal-time-title {
  margin-top: 0;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@-webkit-keyframes rollout {
  0% { -webkit-transform: translateY(500px); transform: translateY(500px); }
  100% { -webkit-transform: none; transform: none; }
}

@keyframes rollout {
  0% { -webkit-transform: translateY(500px); transform: translateY(500px); }
  100% { -webkit-transform: none; transform: none; }
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
  .menu-main {
    grid-row-gap: 2.5rem;
  }

  .menu-meal-time-title {
    font-size: 15pt;
    margin-bottom: 30px;
  }
}
.top-buttons-div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.top-buttons-div select {
    margin-top: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    border-radius: 25px;
    padding: 1rem 1.5rem;
    min-width: 100px;
    color: white;
    background-color: black;
    box-shadow: 6px 6px 1px #FF6E59;
    -webkit-appearance: none;
            appearance: none;
}

.meal-plan-cart-button {
    background: #000;
    color: #fff;
    width: 190px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem 1.5rem;
    outline: none;
    border: none;
    top: 0rem;
    right: 16vw;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 2.5rem;
    transition: 0.3s;
    box-shadow: 6px 6px 1px #FF6E59;
}
  
.meal-plan-cart-button:hover {
    background: #FF6E59;
    box-shadow: 6px 6px 1px #000;
}

.in-dev-div {
    text-align: center;
    padding: 32pt;
}

.in-dev-div h2 {
    padding: 0;
    margin: 0;
}

.in-dev-div h3 {
    padding: 0;
    margin: 0;
}

.selected-meal-plan-details-div {
    padding-top: 42px;
    text-align: center;
}

.selected-meal-plan-details-div h1 {
    padding: 0;
    margin: 0;
}

.selected-meal-plan-details-div p {
    -webkit-font-feature-settings: "pcap", "c2pc";
            font-feature-settings: "pcap", "c2pc";
    font-variant-caps: all-petite-caps;
    font-size: 14pt;
    font-weight: 500;
}


.worked-on-div {
    padding: 20px;
    color: white;
    font-size: 12pt;
    font-weight: 600;
    text-align: center;
    background-color: #FF6E59;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}


@media (max-width: 1500px) {
    .meal-plan-cart-button {
        right: 12vw;
    }
}

@media (max-width: 1400px) {
    .meal-plan-cart-button {
        right: 10vw;
    }
}

@media (max-width: 1300px) {
    .meal-plan-cart-button {
        right: 8vw;
    }
}

@media (max-width: 1000px) {
    .meal-plan-cart-button {
        right: 6vw;
    }
}

@media (max-width: 800px) {
    .meal-plan-cart-button {
        right: 4vw;
    }
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .top-buttons-div select {
        font-size: 0.8rem;
    }
    
    .meal-plan-cart-button {
        width: 150px;
        font-size: 0.8rem;
    }

    .top-buttons-div .back-button {
        font-size: 0.8rem;
        width: 122px;
    }

    .top-buttons-div {
        margin-right: 4px;
    }

    .worked-on-div {
        font-size: 10pt;
    }
}
.menu-xtra-main {
    padding-top: 3.5rem;
    margin-top: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 1rem;
    -webkit-animation: rollout 0.4s;
            animation: rollout 0.4s;
}


.privacy-policy-div {
    margin-bottom: 128px;
}
.privacy-policy-div h1 {
    text-align: center;
}

.privacy-policy-div h3 {
    margin-left: 0;
    margin-top: 16px;
    margin-bottom: 16px;
}

.privacy-policy-div p {
    padding: 3px;
}

