.menu-cart-main {
    display: flex;
    flex-direction: column;
}

.meal-plan-cart-item-div {
    max-width: 520px;
    padding: 20px;
    margin-left: 32px;
    margin-top: 16px;
    border-style: solid;
    border-color: #FF6E59;
    border-width: 1px;
    border-radius: 16px;
    display: flex;
    box-shadow: 6px 6px 1px #FF6E59;
}

.meal-plan-cart-item-div img {
    width: 200px;
    border-radius: 16px;
    margin-left: -48px;
    margin-top: -18px;
    margin-bottom: -16px;
}

.meal-plan-cart-item-info-div h1 {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.meal-plan-cart-item-info-div p {
    margin-left: 16px;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .meal-plan-cart-item-div img {
        width: 150px;
    }

    .meal-plan-cart-item-info-div h1 {
        font-size: 16pt;
        margin-left: 12px;
    }
    
    .meal-plan-cart-item-info-div p {
        font-size: 11pt;
        margin-left: 12px;
    }
}

/* iPhone 8 */
@media (max-width: 400px) {
    .meal-plan-cart-item-div img {
        width: 140px;
    }

    .meal-plan-cart-item-info-div h1 {
        font-size: 14pt;
        margin-left: 10px;
    }
    
    .meal-plan-cart-item-info-div p {
        font-size: 10pt;
        margin-left: 10px;
    }
}

/* iPhone SE */
@media (max-width: 330px) {
    .meal-plan-cart-item-div img {
        width: 120px;
    }

    .meal-plan-cart-item-info-div h1 {
        font-size: 12pt;
        margin-left: 8px;
    }
    
    .meal-plan-cart-item-info-div p {
        font-size: 9pt;
        margin-left: 8px;
    }
}