header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.5rem 0 0 0;
  height: 100vh;
}

.company-name {
  animation: comeFromLeft 0.3s;
}

.company-info {
  animation: comeFromLeft 0.6s;
}

.view-plans-button {
  animation: comeFromLeft 0.9s;
}

.header-img, .header-mobile-img {
  width: 100%;
  align-self: center;
  border-radius: 15%;
  animation: comeFromRight 0.3s;
}

.header-mobile-img {
  visibility: hidden;
  height: 0px;
}

@keyframes comeFromLeft {
  0% { transform: translateX(-500px); }
  100% { transform: none; }
}

@keyframes comeFromRight {
  0% { transform: translateX(500px); }
  100% { transform: none; }
}

.header-content {
  display: flex;
  flex-direction: column;
}

.content-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.content-main h1 {
  margin-left: 0;
  font-size: 4rem;
  margin-bottom: 1.5rem;
}

.content-main button {
  background: #000;
  color: #fff;
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 2.5rem;
  transition: 0.3s;
  box-shadow: 6px 6px 1px #FF6E59;
}

.content-main button:hover {
  background: #FF6E59;
  box-shadow: 6px 6px 1px #000;
}

.content-main p {
  font-weight: 500;
  font-size: 16pt;
}

.soon-available {
  font-size: 12pt;
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
  header {
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
    text-align: center;
  }

  header .header-img {
    visibility: hidden;
    height: 0px;
  }

  header .header-mobile-img {
    visibility: visible;
    width: 90%;
    height: auto;
    margin-top: 32px;
  }

  .content-main button {
    margin-left: auto;
    margin-right: auto;
  }
  
  .content-main h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .content-main p {
    font-size: 14pt;
    padding-left: 16px;
    padding-right: 16px;
  }

  .soon-available {
    font-size: 11pt;
  }
}

/* iPhone SE */
@media (max-width: 320px) {
  .content-main h1 {
    font-size: 2.5rem;
  }

  .content-main p {
    font-size: 12pt;
  }

  .soon-available {
    font-size: 10pt;
  }
}