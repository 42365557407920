.check-delivery-button {
    position: fixed;
    top: 1.5rem;
    left: 1rem;
    background: #000;
    color: #fff;
    width: 280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    outline: none;
    border: none;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 6px 6px 1px #FF6E59;
}

.check-delivery-button:hover {
    background: #FF6E59;
    box-shadow: 6px 6px 1px #000;
}

.header-background {
    position: fixed;
    top: 0rem;
    left: 0rem;
    width: 100vw;
    height: 105px;
    background-color: #f8f8f87d;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .header-background {
        background-color: transparent;
        visibility: hidden;
        opacity: 0;
    }

    .check-delivery-button {
        width: 202px;
        padding: 0.85rem 0.88rem;
        font-size: 0.75rem;
        box-shadow: 4px 4px 1px #FF6E59;
    }
}