/* The Modal (background) */
.modal-post-code {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(255, 255, 255); /* Fallback color */
    background-color: rgba(255, 255, 255, 0.38); /* Black w/ opacity */
    backdrop-filter: blur(10px);
    animation: zoomIn 0.3s;
}

@keyframes zoomIn {
    0% { transform: scale(0) }
    60% { transform: scale(1.1) }
    100% { transform: scale(1.0) }
}

/* Modal Content/Box */
.modal-content-post-code {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
    border-radius: 32px;
    text-align: center;
    width: 50%; /* Could be more or less, depending on screen size */
    border: 0.5px solid #FF6E59;
    box-shadow: 6px 6px 1px #FF6E59;
    position: relative;
}

.post-code-question {
    font-size: 14pt;
    padding-left: 40px;
    padding-right: 40px;
}

.post-code-form {
    margin-top: 16px;
}

.post-code-text-field {
    font-family: 'Montserrat', sans-serif;
    font-variant-caps: all-petite-caps;
    font-size: 14pt;
    font-weight: 600;
    width: 200px;
    height: 35px;
    text-align: center;
    border-radius: 16px;
    border-style: dashed;
    border-width: 1px;
    box-shadow: 2px 2px 1px #FF6E59;
}

.post-code-text-field:focus {
    border-color: black;
    border-width: 1px;
    border-style: solid;
    box-shadow: 2px 2px 1px #FF6E59;
    outline: none;
}

.post-code-sorry {
    margin-top: 16px;
    font-size: 14pt;
    font-weight: 500;
}

.post-code-success {
    margin-top: 16px;
    font-size: 14pt;
    font-weight: 500;
}

.pop-up-response-div {
    animation: expand 0.3s;
}

@keyframes expand {
    0% { transform: translateY(-10px); }
    100% { transform: none; }
}

.post-code-sorry-button {
    margin-top: 16px;
	font-size: 18px;
    width: 250px;
	height: 50px;
    margin-right: 0;
    margin-left: auto;
	background-color: #000;
    border-radius: 22px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.post-code-success-button {
    margin-top: 16px;
	font-size: 18px;
    width: 270px;
	height: 50px;
    margin-right: 0;
    margin-left: auto;
	background-color: #000;
    border-radius: 26px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.post-code-success-button:hover {
	background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

.post-code-icon {
    padding-right: 6px;
}

.modal-close-button-post-code {
    position: absolute;
    right: 16px;
    top: 16px;
	font-size: 18px;
    width: 50px;
	height: 50px;
    margin-right: 0;
    margin-left: auto;
	background-color: #000;
    transform: rotate(45deg);
    border-radius: 15px;
	color: #fff;
	cursor: pointer;
    border: none;
    transition: 0.3s;
    box-shadow: 4px 4px 1px #FF6E59;
}

.modal-close-button-post-code:hover {
    background-color: #FF6E59;
    box-shadow: 4px 4px 1px #000;
}

.modal-close-button-post-code i {
    transform: rotate(-45deg);
}


/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
    .modal-content-post-code {
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
        width: 90%;
    }

    .post-code-question {
        font-size: 12pt;
        text-align: left;
        padding-left: 20px;
        padding-right: 80px;
    }

    .post-code-sorry, .post-code-success {
        font-size: 11pt;
        padding-left: 6px;
        padding-right: 6px;
        font-weight: 500;
    }

    .post-code-sorry-button {
        font-size: 16px;
        width: 220px;
    }

    .post-code-success-button {
        font-size: 16px;
        width: 270px;
    }
}

/* iPhone 8 */
@media (max-width: 375px) {
    .modal-content-post-code {
        width: 91%;
    }

    .post-code-question {
        font-size: 11pt;
        text-align: left;
        padding-left: 20px;
        padding-right: 80px;
    }

    .post-code-sorry {
        font-size: 10pt;
    }

    .post-code-sorry-button {
        font-size: 15px;
        width: 200px;
    }

    .post-code-success-button {
        font-size: 15px;
        width: 260px;
    }
}