.menu-main {
  padding-top: 3.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-row-gap: 5rem;
  animation: rollout 0.4s;
}

.menu-meal-time-title {
  margin-top: 0;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@keyframes rollout {
  0% { transform: translateY(500px); }
  100% { transform: none; }
}

/* iPhone 13 Pro Max and bigger */
@media (max-width: 500px) {
  .menu-main {
    grid-row-gap: 2.5rem;
  }

  .menu-meal-time-title {
    font-size: 15pt;
    margin-bottom: 30px;
  }
}