.top-buttons-div {
    display: flex;
    justify-content: space-between;
}

.top-buttons-div select {
    margin-top: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    border-radius: 25px;
    padding: 1rem 1.5rem;
    min-width: 100px;
    color: white;
    background-color: black;
    box-shadow: 6px 6px 1px #FF6E59;
    appearance: none;
}

.meal-plan-cart-button {
    background: #000;
    color: #fff;
    width: 190px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    outline: none;
    border: none;
    top: 0rem;
    right: 16vw;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 2.5rem;
    transition: 0.3s;
    box-shadow: 6px 6px 1px #FF6E59;
}
  
.meal-plan-cart-button:hover {
    background: #FF6E59;
    box-shadow: 6px 6px 1px #000;
}

.in-dev-div {
    text-align: center;
    padding: 32pt;
}

.in-dev-div h2 {
    padding: 0;
    margin: 0;
}

.in-dev-div h3 {
    padding: 0;
    margin: 0;
}

.selected-meal-plan-details-div {
    padding-top: 42px;
    text-align: center;
}

.selected-meal-plan-details-div h1 {
    padding: 0;
    margin: 0;
}

.selected-meal-plan-details-div p {
    font-variant-caps: all-petite-caps;
    font-size: 14pt;
    font-weight: 500;
}


.worked-on-div {
    padding: 20px;
    color: white;
    font-size: 12pt;
    font-weight: 600;
    text-align: center;
    background-color: #FF6E59;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}


@media (max-width: 1500px) {
    .meal-plan-cart-button {
        right: 12vw;
    }
}

@media (max-width: 1400px) {
    .meal-plan-cart-button {
        right: 10vw;
    }
}

@media (max-width: 1300px) {
    .meal-plan-cart-button {
        right: 8vw;
    }
}

@media (max-width: 1000px) {
    .meal-plan-cart-button {
        right: 6vw;
    }
}

@media (max-width: 800px) {
    .meal-plan-cart-button {
        right: 4vw;
    }
}

/* iPhone 13 Pro Max  and bigger */
@media (max-width: 500px) {
    .top-buttons-div select {
        font-size: 0.8rem;
    }
    
    .meal-plan-cart-button {
        width: 150px;
        font-size: 0.8rem;
    }

    .top-buttons-div .back-button {
        font-size: 0.8rem;
        width: 122px;
    }

    .top-buttons-div {
        margin-right: 4px;
    }

    .worked-on-div {
        font-size: 10pt;
    }
}